import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <SEO title="404: Not Found" />
        <article className="post-content page-template no-image">
          <div className="post-content-body">
            <h1 className="text-align-center">Not Found</h1>
            <p className="text-align-center">
              You just hit something that doesn&#39;t exist.
              <br />
              Or once a memory had been long gone...
            </p>
          </div>
        </article>
      </Layout>
    )
  }
}

export default NotFoundPage
